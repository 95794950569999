import React from "react"
import "./one-column-list.css"
import Button from '../button'


const OneColumnCenteredList = ({ content }) => {


  return (
    <section className="pb-[5.5vw] pv-[10vw] md:pv-[5vw]" >
      <div className="container-sm text-center">
        <h2 className="text-[2.3vw]  text-dark-blue">{content.listHeding}</h2>

            {content.list.map((item, key) => (

                <div className="one-col-list-item" key={key}>{item.listItem}</div>
            ))}

        {content.listButton &&
          <Button classes="btn bg-yellow text-dark-blue btn-50 md:w-1/2 mt-[5.5vw]" data-scroll cta={content.listButton}>{content.listButton.title}</Button>
        }

      </div>
    </section>
  )
}

export default OneColumnCenteredList
