import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
// import Seo from "../components/seo"

import Hero from "../components/hero"
import Contact from "../components/contact"
import Crew from "../components/crew"
import SimpleText from "../components/simple-text"
import TwoColumn from "../components/two-column"
import ItineraryItem from "../components/itinerary-item"
import BoatFeature from "../components/boat-feature"
import SimpleHero from "../components/simple-hero"
import VanishAccordion from "../components/accordion"
import Cuisine from "../components/cuisine"
import Video from "../components/video"
import Gallery from "../components/gallery"
import Seo from "../components/seo"
import OneColumnCenteredList from "../components/one-column-list/index"
import TabsRender from "../components/pricing-table"

const fragment = (postType) => {
  console.log(postType + " is the type");
}

export const query = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      slug
      seo {
        metaDesc
        readingTime
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      layouts {
        layouts {
          ... on WpPage_Layouts_Layouts_Hero {
            fieldGroupName
            headline
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            cta {
              target
              title
              url
            }
            showBookingButton
          }
          ... on WpPage_Layouts_Layouts_Itinerary {
             fieldGroupName
             map {
               localFile {
                 childImageSharp {
                   gatsbyImageData(layout: FULL_WIDTH)
                 }
               }
             }
             layout
             itineraryUrl
             headline
             list {
               item
             }
             roverdUrl
             roverdCal
             shortDescription
           }
          ... on WpPage_Layouts_Layouts_SimpleText {
            content
            fieldGroupName
          }
          ... on WpPage_Layouts_Layouts_Contact {
            fieldGroupName
            address
            email
            phone
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    aspectRatio: 1.4
                  )
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_Boat {
            fieldGroupName
            headline
            boat {
              ... on WpBoat {
                id
                title
                slug
                acfBoats {
                  capacity
                  crew
                  description
                  features {
                    feature
                  }
                  length
                  startingPrice
                  type
                  featureImages {
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            height: 800
                            width: 1000
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                          )
                        }
                      }
                    }
                  }
                  images {
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            height: 800
                            width: 1000
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_Gallery {
            fieldGroupName
            gallery {
              fieldGroupName
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_Content {
            content
            fieldGroupName
          }
          ... on WpPage_Layouts_Layouts_Accordion {
            fieldGroupName
            sectionHeadding
            accordionItem {
              featureCopy
              details
              fieldGroupName
              headline
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_Crew {
            content
            fieldGroupName
            headline
            layout
            position
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_SimpleHero {
            fieldGroupName
            headline
          }
          ... on WpPage_Layouts_Layouts_Accordion {
            fieldGroupName
            sectionHeadding
            accordionItem {
              details
              fieldGroupName
              headline
              featureCopy
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_Cuisine {
            content
            fieldGroupName
            headline
            layout
            subHeadline
            images {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_Spacer {
            fieldGroupName
          }
          ... on WpPage_Layouts_Layouts_2Column {
            fieldGroupName
            content
            headline
            images {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            layout
            cta {
              target
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_OneColumnCenteredList {
            fieldGroupName
            listHeding
            list {
              listItem
            }
            listButton {
              target
              title
              url
            }
            showBookingButton
          }
          ... on WpPage_Layouts_Layouts_PricingTable {
            availabilityTable
            fieldGroupName
            inquireTab
            preHeadline
            headline
            priceTable
          }
        }
      }
    }
  }
`

const WpPage = ({  data: {
    wpPage: { title, content, layouts, id, slug, seo },
  }}) => {

  return (
    <Layout slug={slug}>
      {seo && (
        <Seo
          title={seo.title || title}
          description={seo.metaDesc}
          seo={seo}
        />
      )}
        {/* <h2 className="text-center">Sailing soon!</h2> */}
      
      {layouts.layouts && layouts.layouts.map(( layout, index ) => {
        if(layout.fieldGroupName === "Page_Layouts_Layouts_Hero") {
          return ( <Hero content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_SimpleText") {
          return ( <SimpleText content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_2Column") {
          return ( <TwoColumn content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_Itinerary") {
          return ( <ItineraryItem content={layout} key={index} id={slug} page={id} /> )
        }  else if(layout.fieldGroupName === "Page_Layouts_Layouts_Contact") {
          return ( <Contact content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_Boat") {
          return ( <BoatFeature content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_Crew") {
          return ( <Crew content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_SimpleHero") {
          return ( <SimpleHero content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_Accordion") {
          return ( <VanishAccordion content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_Cuisine") {
          return ( <Cuisine content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_Spacer") {
          return ( <div className="mt-[8vw]" /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_Content") {
          return ( <Video content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_Gallery") {
          return ( <Gallery content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_OneColumnCenteredList") {
          return ( <OneColumnCenteredList content={layout} key={index} id={slug} page={id} /> )
        } else if(layout.fieldGroupName === "Page_Layouts_Layouts_PricingTable") {
          return ( <TabsRender content={layout} key={index} id={slug} page={id} /> )
        }
      })}
     
    </Layout>
  )
}

export default WpPage
